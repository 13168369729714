import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "../context/ThemeContext";

const ExportExcelModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "var(--primaryColor)",
  border: "none",
  boxShadow: 24,
  borderRadius: "8px",
};

const ITEM_HEIGHT = 30;

const ExportExcelModal = ({
  exportExcelModalOpen,
  setExportExcelModalOpen,
  handleModalSubmit,
}) => {
  const [trip, setTrip] = useState(process.env.REACT_APP_DEFAULTEMPLATE);
  const [selectedDistance, setSelectedDistance] = useState("KM");
  const [format, setFormat] = useState(".xlsx");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme().themeMode;

  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  const token = localStorage.getItem("token");
  const { startDate, endDate } = useSelector(
    (state) => state.datefilterreducer
  );

  let exportedExcelSheetName = `${fleetData?.fleetName} - ${
    startDate
      ? new Date(startDate).toLocaleString("en-US").split(",")[0]
      : new Date().toLocaleString("en-US").split(",")[0]
  } - ${
    endDate
      ? new Date(endDate).toLocaleString("en-US").split(",")[0]
      : new Date().toLocaleString("en-US").split(",")[0]
  }`;

  const [fileName, setFileName] = useState(exportedExcelSheetName);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Modal
      open={exportExcelModalOpen}
      //   onHide={() => setAddLocationModalOpen(false)}
      backdrop="static"
      keyboard="false"
    >
      <Box
        sx={{
          ...ExportExcelModalStyle,
          width: isMobile ? "100%" : "40%",
          height: isMobile ? "100%" : "auto",
        }}
      >
        <div className="!p-4 d-flex-center justify-center OrderModal_heading_wrapper">
          <span className="Order_modal_h3"> Export Excel</span>
        </div>
        <div className="h-[calc(100%-89px)]">
          <form
            className="h-full"
            onSubmit={(e) => {
              e.preventDefault();

              handleModalSubmit(trip, selectedDistance, fileName, format);
            }}
          >
            <div className="overflow-scroll max-h-[calc(100%-124px)]  px-4 flex flex-row justify-between">
              {" "}
              <div className="flex items-center text-textColor">
                <div className="mr-2">
                  <img
                    src={
                      theme === "K-Light"
                        ? "/assets/images/distance-dark.svg"
                        : "/assets/images/distance.svg"
                    }
                  />
                </div>
                <Select
                  value={selectedDistance}
                  onChange={(e) => setSelectedDistance(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "40px",
                    width: "180px",
                    color: "var(--textColor)",
                    border: "1px solid #4B5263",
                    "& .MuiSelect-icon": {
                      color: "#4B5263",
                    },
                  }}
                  className=""
                >
                  {["KM", "miles", "meters"].map((item, index) => (
                    <MenuItem key={index + 1} value={item} style={{}}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {process.env.REACT_APP_TRIP && (
                <div className="flex items-center text-textColor">
                  <div className="mr-2">
                    <img src="/assets/images/merged.svg" />
                  </div>
                  <Select
                    value={trip}
                    onChange={(e) => setTrip(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: "40px",
                      width: "180px",
                      color: "var(--textColor)",
                      border: "1px solid #4B5263",
                      "& .MuiSelect-icon": {
                        color: "#4B5263",
                      },
                    }}
                    className=""
                  >
                    {["Merged", "Orders", "Trips"].map((item, index) => (
                      <MenuItem key={index + 1} value={item} style={{}}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            </div>
            <div className="overflow-scroll max-h-[calc(100%-124px)]  px-4 flex flex-row justify-between">
              {" "}
              <div className="flex items-center text-textColor w-full mt-4">
                <input
                  className="flex-1 py-2 rounded-md px-2 mr-2 bg-transparent border-[#4B5263] border-[1px]"
                  value={fileName}
                  onChange={(e) => setFileName(() => e.target.value)}
                  placeholder="Enter File Name"
                />
                <Select
                  value={format}
                  onChange={(e) => setFormat(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "40px",
                    width: "100px",
                    color: "var(--textColor)",
                    border: "1px solid #4B5263",
                    "& .MuiSelect-icon": {
                      color: "#4B5263",
                    },
                  }}
                  className=""
                >
                  {[".xlsx", ".csv"].map((item, index) => (
                    <MenuItem key={index + 1} value={item} style={{}}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            <div className="absolute inset-x-0 bottom-0 flex justify-between px-3 Order_modal_button_wrapper sm:relative">
              <button
                onClick={() => {
                  setExportExcelModalOpen(false);
                }}
                className="!p-[10px_20px] m-[20px] font-bold text-textColor transition-all border-2 rounded-lg bg-ottonomyDarkBlue border-ottonomyBlue hover:bg-ottonomyBlue"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="transition-all Order_save_button text-white hover:bg-ottonomyBlue"
              >
                Export
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default ExportExcelModal;
